import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout';
import Hero from '../components/hero';
import LogoFull from '../components/logoFull';
import Portrait from '../components/portrait';
import SEO from '../components/seo';
// import Ribbon from '../components/ribbon';
// import CTA from '../components/cta';
// import ActionCenter from '../components/ActionCenter';
import EndorsementsGrid from '../components/endorsementsGrid';

const IndexPage = () => {
  const { allContentfulIssue } = useStaticQuery(
    graphql`
      query {
        allContentfulIssue(limit: 6) {
          totalCount
          edges {
            node {
              title
              excerpt {
                id
                excerpt
              }
              header {
                fixed(cropFocus: FACE, quality: 80, width: 640, height: 360) {
                  src
                }
              }
            }
          }
        }
      }
    `,
  );

  const { totalCount, edges } = allContentfulIssue;

  const issueColumnClass = edges.length % 3 === 0 ? 'column is-one-third' : 'column is-half';

  return (
    <Layout>
      <SEO title="Vanna Howard for State Representative" />

      <Hero color="primary is-bold is-hidden-mobile" centered>
        <div style={{ maxWidth: '450px', width: '100%', margin: '0 auto' }}>
          <LogoFull fill="#fff" width="100%" />
        </div>
      </Hero>


      <main className="section">
        <div className="container content">
          <div className="columns is-variable is-8" style={{ maxWidth: 1200, margin: '0 auto' }}>
            <div className="column is-two-fifths">
              <Portrait />
            </div>
            <div className="column">
              <p className="title is-4">Meet Vanna</p>
              <p>
                My name is Vanna Howard and I am a Democrat running for the 17th Middlesex District
                State Representative seat. I am running because I strongly feel the need to give
                back to a place which has been so good to me.
              </p>
              <p>
                <Link to="announcement" className="">
                  Continue reading...
                </Link>
              </p>
              <hr />
              <div className="notification">
                <p className="title is-5">Get Involved</p>
                <p>
                  We're still not sure what a political campaign looks like during a pandemic, but
                  we know one thing remains universal: a grassroots campaign like ours is only as
                  strong as its supporters. If you can put out a yard sign, volunteer your time, or
                  pitch in a few dollars, please sign up now!
                </p>
                <p>
                  <Link to="yard-sign" className="button is-primary">
                    Get a Yard Sign
                  </Link>{' '}
                  <Link to="get-involved" className="button is-success">
                    Volunteer
                  </Link>{' '}
                  <a
                    href="https://secure.actblue.com/contribute/page/vannahoward"
                    className="button is-danger"
                  >
                    Donate Now
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <EndorsementsGrid/>
      <div className="section has-background-primary-dark	 has-text-white">
        <div className="container" style={{ padding: '24px 0' }}>
          <h2 className="heading is-size-4 has-text-centered has-text-weight-bold">
            My Priorities
          </h2>
          <hr style={{ opacity: 0.5 }} />
          <nav className="columns is-multiline ">
            {edges.map(
              ({
                node: {
                  title,
                  excerpt,
                  header: {
                    fixed: { src },
                  },
                },
              }) => {
                return (
                  <div className={issueColumnClass}>
                    <div style={{ padding: '24px 0' }}>
                      <figure className="image is-16by9" style={{ margin: '0 auto 24px' }}>
                        <img className="" src={src} style={{}} />
                      </figure>
                      <p className="heading is-size-5 has-text-centered has-text-weight-bold">
                        {title}
                      </p>
                      <div>{excerpt && excerpt.excerpt}</div>
                    </div>
                  </div>
                );
              },
            )}
          </nav>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
