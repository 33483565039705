import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';

const EndorsementsGrid = () => {
  const { endorsements } = useStaticQuery(
    graphql`
      query {
        endorsements: allContentfulEndorsement(filter: { type: { eq: "Individual" } }) {
          totalCount
          edges {
            node {
              title
              shortTitle
              excerpt {
                id
                excerpt
              }
              photo {
                fixed(cropFocus: FACE, quality: 80, width: 600, height: 600) {
                  src
                }
              }
            }
          }
        }
      }
    `,
  );
  console.log(endorsements);
  const grid = endorsements
    ? endorsements.edges.map(({ node }) => (
        <div className="column is-half is-one-third-desktop ">
          <div className="card">
            <div className="card-image">
              <figure className="image is-16x9">
                <img src={node.photo && node.photo.fixed.src} alt={node.title} />
              </figure>
            </div>
            <div className="card-content">
              <div className="content">
                <p className="title is-5">{node.title}</p>
                <p className="subtitle is-6">{node.shortTitle}</p>

                <div>
                  <small>{node.excerpt && node.excerpt.excerpt}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))
    : null;

  return (
    <div className="section has-background-light">
      <div className="container">
      <p className="title has-text-weight-bold  is-2 has-text-centered">
        Community Leaders Agree: We Trust Vanna Howard!
      </p>

      <div className="columns is-multiline">{grid}</div>
      <br />
      <div className="has-text-centered">
        <p>
          <Link className="button is-large is-primary" to="support">
            Share Your Support
          </Link>
        </p>
      </div>
      </div>
    </div>
  );
};

EndorsementsGrid.propTypes = {};

export default EndorsementsGrid;
